/* Tailwind Import */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #fff;
  margin: 0;
  font-family: "Helvetica";
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

a {
  @apply underline underline-offset-4 decoration-red-500
}

a:hover {
  @apply underline underline-offset-4 decoration-black
}

input, textarea {
  @apply border border-gray-300 py-1 px-2 w-full shadow-md
}

.button-full {
  @apply px-2 py-2 bg-black text-yellow-400 mt-4 w-full
}

.button-no-margin {
  @apply px-2 py-2 bg-black text-yellow-400
}

.button-standard {
  @apply px-2 py-2 mr-2 bg-black text-yellow-400 mt-4
}

.button-delete {
  @apply text-red-500 mr-2 px-2 py-2 text-sm border border-red-500 hover:bg-red-500 hover:text-white mt-4
}

.button-edit {
  @apply mr-2 px-2 py-2 text-sm border border-black hover:bg-gray-200 hover:text-white mt-4
}

.button-edit:hover {
  @apply text-black no-underline
}


/* Adding alternating background colours to the rows of a table */
.table-row:nth-child(even) {
  @apply bg-gray-100;
}
.table-row:nth-child(odd) {
  @apply bg-white;
}

/* Radio buttons for Theme selection within the business creation form */
.theme-radio-button {
  accent-color: red;
}

.theme-radio-group label {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 10px;
  border: 1px solid black;
}

.blog-post {
  @apply mb-16 leading-7 px-2
}

.blog-post p {
  @apply mb-3
}

.blog-post h2 {
  @apply text-xl mb-2 mt-6
}

.blog-post .subtitle {
  @apply mb-4 text-gray-500 text-center
}